<template>
  <div class="showcase">
    <header>
      <video loop alt="Background" autoplay muted ref="video" poster="@/assets/img/offers/renovation-2025/bg.jpg" class="desktop">
        <source src="@/assets/img/offers/renovation-2025/2025.mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <img src="@/assets/img/offers/renovation-2025/bg.jpg" class="mobile">
      <div>
        <div class="image">
          <span class="close" @click="back">
            <img src="@/assets/img/showcase/back.svg" alt="Back" />
          </span>
          <img
            :src="require(`@/assets/img/offers/renovation-2025/svg_${$i18n.locale}.svg`)"
          />
        </div>
        <footer class="desktop">
          <span></span>
          <div class="buttons">
            <!-- <ButtonSmaller @click="scrollTo('prices')">{{
              $t("vip.programme")
            }}</ButtonSmaller>
            <ButtonSmaller @click="scrollTo('programme')">{{
              $t("vip.dinner")
            }}</ButtonSmaller> -->
          </div>
        </footer>
      </div>
    </header>
    <div class="showcase-content" ref="prices">
      <div class="showcase-about">
        <div class="txts">
          <h2>{{ $t("renovation-title") }}</h2>
          <p>
            {{ $t("renovation-b1") }}
          </p>
          <p>
            {{ $t("renovation-b2") }}
          </p>
          <p>
            {{ $t("renovation-b3") }}
          </p>
          <p>
            {{ $t("renovation-b4") }}
          </p>
         
          
        </div>
        <div class="img-and-features">
          <p class="mini-title">{{ $t("vip.r.prices-1") }}</p>
          <div class="image">
            <img
              :src="
                require(`@/assets/img/offers/renovation-2025/2.jpg`)
              "
              :alt="$t('alt.prices-table')"
            />
          </div>
        </div>
      </div>
      <p class="mini-title" ref="programme">{{ $t("renovation-title") }}</p>
      <div class="showcase-about showcase-about--strech">
        <div class="txts">
          <p>
            {{ $t("renovation-2") }}
          </p>
           <p>
            {{ $t("renovation-3") }}
          </p>
           <p>
            {{ $t("renovation-4") }}
          </p>
           <p>
            {{ $t("renovation-5") }}
          </p>
           <p>
            {{ $t("renovation-6") }}
          </p>
           <p>
            {{ $t("renovation-7") }}
          </p>
           <p>
            {{ $t("renovation-8") }}
          </p>
          <p>
            {{ $t("renovation-10") }}
          </p>
        </div>
        <div class="img-and-features">
          <NewYearCarousel
            :images="[
              require('@/assets/img/offers/renovation-2025/2.jpg'),
              require('@/assets/img/offers/renovation-2025/1.jpg'),
              require('@/assets/img/offers/renovation-2025/3.jpg'),
              require('@/assets/img/offers/renovation-2025/15.jpg'),
              require('@/assets/img/offers/renovation-2025/19.jpg'),
            ]"
          />
        </div>
      </div>

      <p class="mini-title">{{ $t(`vip.inquiry`) }}</p>
      <Inquiry mini="true" ref="inquiry" />
      <p class="last">
        <span>{{ $t("vip.r.last-1") }}</span>
      </p>
    </div>
  </div>
</template>

<script>
// import ButtonSmaller from "@/components/ButtonSmaller";
import NewYearCarousel from "./NewYearCarousel";
import Inquiry from "./Inquiry";
export default {
  components: {
    // ButtonSmaller,
    NewYearCarousel,
    Inquiry,
  },
  props: ["showcase", "previousShowcase", "nextShowcase"],
  computed: {
    show() {
      return this.$route.name === "rooms" || this.$route.name === "halls";
    },
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    scrollTo(str) {
      const el = this.$refs[str];

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/showcase.scss";
</style>
