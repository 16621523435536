var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner-page"},[_c('header',{staticClass:"inner-page__header"},[_c('aside',{staticClass:"inner-page__aside"},[_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[_c('img',{staticClass:"svg",attrs:{"src":require(`@/assets/img/kids/svg_${_vm.$i18n.locale}.svg`),"alt":_vm.$t('kids-alt')}})]),_c('p',[_c('img',{staticClass:"scroll-h",attrs:{"src":require("@/assets/img/scroll_h.svg")}}),_vm._v(" "+_vm._s(_vm.$t('kids-p'))+" ")])],1),_c('div',{staticClass:"inner-page__img"})]),_c('main',{staticClass:"inner-page__main"},[_c('About'),_c('section',{staticClass:"inner-page__section"},[_c('Slideshow',{attrs:{"reversed":true,"images":[
          require('@/assets/img/kids/s1-1.jpeg'),
          require('@/assets/img/kids/s1-2.jpeg'),
          require('@/assets/img/kids/s1-3.jpeg'),
          require('@/assets/img/kids/s1-4.jpeg'),
          require('@/assets/img/kids/s1-5.jpeg'),
          require('@/assets/img/kids/s1-6.jpeg'),
          require('@/assets/img/kids/s1-7.jpeg'),
          require('@/assets/img/kids/s1-8.jpeg'),
          require('@/assets/img/kids/s1-9.jpeg'),
          require('@/assets/img/kids/s1-10.jpeg'),
          require('@/assets/img/kids/s1-11.jpeg'),
          require('@/assets/img/kids/s1-12.jpeg'),
          require('@/assets/img/kids/s1-13.jpeg'),
          require('@/assets/img/kids/s1-14.jpeg'),
          require('@/assets/img/kids/s1-15.jpeg'),
          require('@/assets/img/kids/s1-16.jpeg'),
        ]}}),_c('div',{staticClass:"inner-page__texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t('kids-sc1-ttl')))]),_c('p',{staticClass:"sttl"},[_vm._v(_vm._s(_vm.$t('kids-sc1-sttl')))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('kids-sc1-txt'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('kids-sc1-txt1'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('kids-sc1-txt2'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('kids-sc1-txt3'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('kids-sc1-txt4'))}})])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }