<template>
  <div class="inner-page">
    <header class="inner-page__header">
      <aside class="inner-page__aside">
        <transition name="slide" mode="out-in">
          <img
            :src="require(`@/assets/img/kids/svg_${$i18n.locale}.svg`)"
            :alt="$t('kids-alt')"
            class="svg"
          />
        </transition>
        <p>
          <img src="@/assets/img/scroll_h.svg" class="scroll-h" />
          {{ $t('kids-p') }}
        </p>
      </aside>
      <div class="inner-page__img"></div>
    </header>
    <main class="inner-page__main">
      <About />
      <!-- SECTION 1 -->
      <section class="inner-page__section">
        <Slideshow
          :reversed="true"
          :images="[
            require('@/assets/img/kids/s1-1.jpeg'),
            require('@/assets/img/kids/s1-2.jpeg'),
            require('@/assets/img/kids/s1-3.jpeg'),
            require('@/assets/img/kids/s1-4.jpeg'),
            require('@/assets/img/kids/s1-5.jpeg'),
            require('@/assets/img/kids/s1-6.jpeg'),
            require('@/assets/img/kids/s1-7.jpeg'),
            require('@/assets/img/kids/s1-8.jpeg'),
            require('@/assets/img/kids/s1-9.jpeg'),
            require('@/assets/img/kids/s1-10.jpeg'),
            require('@/assets/img/kids/s1-11.jpeg'),
            require('@/assets/img/kids/s1-12.jpeg'),
            require('@/assets/img/kids/s1-13.jpeg'),
            require('@/assets/img/kids/s1-14.jpeg'),
            require('@/assets/img/kids/s1-15.jpeg'),
            require('@/assets/img/kids/s1-16.jpeg'),
          ]"
        />
        <div class="inner-page__texts">
          <h2>{{ $t('kids-sc1-ttl') }}</h2>
          <p class="sttl">{{ $t('kids-sc1-sttl') }}</p>
          <p v-html="$t('kids-sc1-txt')"></p>
          <p v-html="$t('kids-sc1-txt1')"></p>
          <p v-html="$t('kids-sc1-txt2')"></p>
          <p v-html="$t('kids-sc1-txt3')"></p>
          <p v-html="$t('kids-sc1-txt4')"></p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import About from './About';
import Slideshow from '@/components/Slideshow';
export default {
  components: {
    About,
    Slideshow,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/inner_page.scss';
.inner-page__img {
  background-image: url('../../assets/img/kids/bg.jpg');
}

.inner-page__main {
  background-image: url('../../assets/img/bg.jpg');
}

.inner-page__header {
  background-color: #ffe667;
}
</style>
